import "css/ScreenContainer.css";

import background from "assets/background.jpeg";

export default function ScreenContainer({ children, topLeft = "" }) {
  return (
    <div
      className="container screen"
      style={{ backgroundImage: `url(${background})` }}
    >
      {children}
    </div>
  );
}
