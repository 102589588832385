import { useNavigate } from "react-router-dom";

import "css/ErrorPage.css";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="container error vertical centered">
      <h1>You got Mortdogged!</h1>
      <p>This page doesn't exist.</p>
      <a onClick={() => navigate("/")}>Return Home</a>
    </div>
  );
}
