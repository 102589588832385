import { useNavigate } from "react-router-dom";

import "css/Home.css";

import balancedBudgetI from "assets/balanced_budget_i.png";
import balancedBudgetII from "assets/balanced_budget_ii.png";

import Button from "components/Button";

export default function Home({ nextGame }) {
  const navigate = useNavigate();

  return (
    <div className="container centered home">
      <div className="container vertical centered">
        <div className="container homeTitleContainer horizontal">
          <img src={balancedBudgetII} />
          <div className="container homeTitle centered">
            <span className="first">FIRST</span>
            <span className="or">to</span>
            <span className="eighth">EIGHTH</span>
          </div>
          <img src={balancedBudgetI} />
        </div>
        <p style={{ textAlign: "center", marginTop: 0 }}>
          A short TFT 🧠 exercise. <br />
          Analyze board strengths and sort them into placement order!
        </p>
        <div className="container vertical homeMenuContainer">
          <Button
            text={"Daily Challenge"}
            color="#5eacff"
            onClick={() => navigate("/daily")}
          />
        </div>
      </div>
    </div>
  );
}
