import hash from "fnv1a";

const CDN_BASE_URL = "https://raw.communitydragon.org/latest/";

export function shuffle(arr) {
  var j, x, index;
  for (index = arr.length - 1; index > 0; index--) {
    j = Math.floor(Math.random() * (index + 1));
    x = arr[index];
    arr[index] = arr[j];
    arr[j] = x;
  }
  return arr;
}

export function mapAssetPath(path) {
  const subpath = path.split("/lol-game-data/assets/")[1].toLowerCase();
  return (
    CDN_BASE_URL + "plugins/rcp-be-lol-game-data/global/default/" + subpath
  );
}

export function swap(list, index1, index2) {
  let temp = list[index1];
  list[index1] = list[index2];
  list[index2] = temp;
  return list;
}

export const move = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function fancyReorder_DOESNTWORK(list, startIndex, endIndex, lockedIndexes) {
  if (lockedIndexes[endIndex] === true || lockedIndexes[startIndex] === true) {
    return list;
  }

  const shiftDirection = endIndex > startIndex ? 1 : -1;
  const smallIndex = Math.min(endIndex, startIndex);
  const bigIndex = Math.max(endIndex, startIndex);
  const result = new Array(list.length);
  for (let i = 0; i < result.length; i++) {
    if (lockedIndexes[i] === true) {
      result[i] = list[i];
    } else {
      if (i == endIndex) {
        result[i] = list[startIndex];
      } else if (i >= smallIndex && i <= bigIndex) {
        result[i] = list[i + shiftDirection];
      }
    }
  }

  return result;
}

export function formatAugmentDescription(augmentData) {
  return augmentData.desc
    .split("<br>")[0]
    .split("@")
    .reduce((acc, val, index) => {
      if (index % 2 === 0) {
        return acc + val;
      } else {
        const valToUse = val.split("*")[0];
        const effectKey = Object.keys(augmentData.effects).find(
          (key) =>
            key.toLowerCase().includes(valToUse.toLowerCase()) ||
            key
              .toLowerCase()
              .includes(hash(valToUse.toLowerCase()).toString(16))
        );
        const effectValue = augmentData.effects[effectKey];

        if (effectValue != null) {
          return acc + Math.round(eval(val.replace(valToUse, effectValue)));
        }
        return acc + val;
      }
    }, "");
}
