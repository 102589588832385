import { db } from "./firebase.js";
import { shuffle } from "./utils.js";
import { getDatabase, ref, onValue, get, child } from "firebase/database";

export async function getDaily() {
  try {
    const dailyMatchIDSnapshot = await get(child(ref(db), "tft/daily"));
    const matchID = dailyMatchIDSnapshot.val();

    const snapshot = await get(child(ref(db), "tft/matches/" + matchID));
    const match = snapshot.val();

    // Random placement: 1 to 7
    // const placement =
    //   Math.floor(Math.random() * (match.info.participants.length - 1)) + 1;
    //
    // const player1 = match.info.participants.find(
    //   (participant) => participant.placement === placement
    // );
    // const player2 = match.info.participants.find(
    //   (participant) => participant.placement === placement + 1
    // );
    return {
      boards: shuffle(match.info.participants),
      mainPlayerName: match.mainPlayerName,
      matchID,
    };
  } catch (e) {
    console.log(e);
    return null;
  }
}
