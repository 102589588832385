import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "./App.css";
import "./css/Animations.css";

import ScreenContainer from "components/ScreenContainer";
import Game from "components/Game";
import Home from "components/Home";
import ErrorPage from "components/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ScreenContainer>
        <Home />
      </ScreenContainer>
    ),
    errorElement: (
      <ScreenContainer>
        <ErrorPage />
      </ScreenContainer>
    ),
  },
  {
    path: "/daily",
    element: (
      <ScreenContainer>
        <Game />
      </ScreenContainer>
    ),
  },
]);

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--main-width",
      isTabletOrMobile ? 100 : 50
    );
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
