import { useEffect, useState } from "react";

import "css/Board.css";

import { Augments, Traits, Units, Player } from "components/BoardComponents";

export default function Board({
  animationType,
  id,
  index,
  board,
  boardStyle,
  onClick,
  showAnswer,
  displayState,
  isDragging,
}) {
  const [currentIndex, setCurrentIndex] = useState(index);
  const [isHovered, setIsHovered] = useState(false);
  const [slideLeftToggle, setSlideLeftToggle] = useState(false);

  const { units, traits, augments, companion, player } = board;

  useEffect(() => {
    if (index === currentIndex) {
      return;
    }
    if (animationType === "slide") {
      setSlideLeftToggle(true);
    }
    setCurrentIndex(index);
  }, [currentIndex, index]);

  let style = {};
  style =
    boardStyle != null
      ? {
          ...style,
          backgroundColor:
            isHovered || isDragging || displayState === "selected"
              ? boardStyle.hoveredBackgroundColor
              : boardStyle.backgroundColor,
        }
      : { ...style };

  let extraClassNames = slideLeftToggle ? "slideleft" : "";
  switch (displayState) {
    case "selected":
      extraClassNames += " selected";
      break;
  }
  return (
    <div
      onClick={onClick}
      className="boardContainer centered vertical"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={"board container horizontal " + extraClassNames}
        style={style}
        onAnimationEnd={() => setSlideLeftToggle(false)}
      >
        <Player
          companion={companion}
          color={boardStyle.foregroundColor}
          level={player.level}
          id={id}
        />
        <div className="container centered vertical traitsAugments">
          <Traits traits={traits} displayState={displayState} id={id} />
          <Augments augments={augments} displayState={displayState} id={id} />
        </div>
        <Units units={units} displayState={displayState} id={id} />
      </div>
    </div>
  );
}
