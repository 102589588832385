import "css/Game.css";

export default function BoardsContainer({ children, boards, decisions }) {
  return (
    <div id="boardsContainer" className="vertical">
      {boards.map((_board, index) => {
        let decisionStyle = "";
        switch (decisions[index]) {
          case true:
            decisionStyle = "correct";
            break;
          case false:
            decisionStyle = "incorrect";
            break;
          default:
            break;
        }
        return (
          <div
            className={"boardPlacementContainer " + decisionStyle}
            style={{
              top: "calc(var(--board-height) * " + index,
            }}
            key={"boardplacementcontainer" + index}
          >
            <div className="boardPlacementNumber">{index + 1}</div>
          </div>
        );
      })}
      {children}
    </div>
  );
}
