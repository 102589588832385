const TRAIT_TO_BACKGROUND = {
  bronze:
    "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-match-history/global/default/bronze-hover.png",
  silver:
    "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-match-history/global/default/silver.png",
  gold: "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-match-history/global/default/gold-1.png",
  chromatic:
    "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-match-history/global/default/chromatic.png",
  threat:
    "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-match-history/global/default/threat-hover.png",
};

const UNIT_RARITY_TO_COLOR = {
  one: "#beb8b1",
  two: "#49b44c",
  three: "#3f73c1",
  four: "#9e66cf",
  five: "#f0c442",
};

const BASE_SATURATION = 30;
const BASE_LIGHTNESS = 20;
const HOVER_LIGHTNESS = 10;

export default {
  getTraitBackground(style) {
    switch (style) {
      case 0:
        return TRAIT_TO_BACKGROUND.threat;
      case 1:
        return TRAIT_TO_BACKGROUND.bronze;
      case 2:
        return TRAIT_TO_BACKGROUND.silver;
      case 3:
        return TRAIT_TO_BACKGROUND.gold;
      case 4:
        return TRAIT_TO_BACKGROUND.chromatic;
    }
    return TRAIT_TO_BACKGROUND.gold;
  },
  getUnitColor(rarity) {
    switch (rarity) {
      case 0:
        return UNIT_RARITY_TO_COLOR.one;
      case 1:
        return UNIT_RARITY_TO_COLOR.two;
      case 2:
        return UNIT_RARITY_TO_COLOR.three;
      case 3:
        return UNIT_RARITY_TO_COLOR.three;
      case 4:
        return UNIT_RARITY_TO_COLOR.four;
      case 5:
        return UNIT_RARITY_TO_COLOR.five;
      case 6:
        return UNIT_RARITY_TO_COLOR.five;
    }
    return UNIT_RARITY_TO_COLOR.one;
  },
  getBoardStyles(count) {
    const STYLE_COUNT = count;
    const hue = Math.floor(Math.random() * 360);

    const styles = [];
    for (let i = 0; i < STYLE_COUNT; i++) {
      const styleHue = hue + i * (360 / STYLE_COUNT);
      styles.push({
        backgroundColor: this.buildHSL(
          styleHue,
          BASE_SATURATION,
          BASE_LIGHTNESS
        ),
        hoveredBackgroundColor: this.buildHSL(
          styleHue,
          BASE_SATURATION + 5,
          BASE_LIGHTNESS + HOVER_LIGHTNESS
        ),
        foregroundColor: this.buildHSL(styleHue, BASE_SATURATION, 60),
      });
    }
    return styles;
  },
  getCorrectBoardStyle() {
    const saturation = 10;
    const lightness = 10;

    const styleHue = 0;

    return {
      backgroundColor: "transparent",
      hoveredBackgroundColor: "transparent",
      foregroundColor: "#fff",
    };
  },
  buildHSL(hue, saturation, lightness) {
    return "hsl(" + hue + ", " + saturation + "%, " + lightness + "%)";
  },
  placementText(placement) {
    switch (placement) {
      case 1:
        return "1st";
      case 2:
        return "2nd";
      case 3:
        return "3rd";
      default:
        return placement + "th";
    }
  },
  guessesToText(guesses) {
    let text = "";
    if (guesses.length === 0) {
      return text;
    }

    for (let i = 0; i < guesses[0].length; i++) {
      for (let j = 0; j < guesses.length; j++) {
        text += guesses[j][i] ? "🟩" : "🟥";
      }
      text += "\n";
    }

    return text;
  },
};
