import { mapAssetPath } from "utils/utils";
import uiutils from "utils/uiutils";

import "css/BoardComponents.css";

import Stars from "components/Stars";
import { Hover } from "components/Hover";

export function Player({ companion, color, level }) {
  return (
    <div className="container player vertical centered">
      <div className="container playerImage" style={{ borderColor: color }}>
        <img src={mapAssetPath(companion.loadoutsIcon)} />
        <div
          className="container playerLevelContainer centered"
          style={{ borderColor: color, color }}
        >
          {level}
        </div>
      </div>
    </div>
  );
}

export function Augments({ augments, id }) {
  return (
    <div className="container horizontal augments centered">
      {augments.map((augment, index) => {
        return (
          <Hover
            title={augment.name}
            description={augment.description}
            placement="bottom"
            key={"augment" + index + id}
          >
            <div className="container augment centered">
              <img src={mapAssetPath(augment.loadoutsIcon)} />
            </div>
          </Hover>
        );
      })}
    </div>
  );
}

export function Traits({ traits, id }) {
  return (
    <div className="container horizontal traits">
      {traits.map((trait, index) => {
        return (
          <Hover
            title={trait.num_units + " " + trait.data.display_name}
            placement="top"
            key={"trait" + index + id}
          >
            <div className="container horizontal trait">
              <div
                className="traitImageContainer"
                style={{
                  backgroundImage:
                    "url(" + uiutils.getTraitBackground(trait.style) + ")",
                }}
              >
                <img
                  style={{
                    filter:
                      trait.name === "Set8_Threat"
                        ? "brightness(0.5) sepia(1) saturate(180%) hue-rotate(240deg)"
                        : "brightness(0)",
                  }}
                  src={mapAssetPath(trait.data.icon_path)}
                />
              </div>
            </div>
          </Hover>
        );
      })}
    </div>
  );
}

export function Units({ units, id }) {
  return (
    <div className="container horizontal units centered">
      {units.map((unit, index) => {
        if (unit.data == null) {
          return;
        }
        return (
          <div
            className="container vertical unit centered"
            key={id + unit.data.display_name + index}
          >
            <Stars
              count={unit.tier}
              rarity={unit.rarity}
              keyProps={{ unitName: unit.data.display_name + index, id }}
            />
            <Hover title={unit.data.display_name} placement="top">
              <img
                className="unitImage"
                src={mapAssetPath(unit.data.squareIconPath)}
                style={{
                  border:
                    "var(--unit-image-border) solid " +
                    uiutils.getUnitColor(unit.rarity),
                }}
              />
            </Hover>
            <Items
              items={unit.items}
              keyProps={{ unitName: unit.data.display_name + index, id }}
            />
          </div>
        );
      })}
    </div>
  );
}

function Items({ items, keyProps }) {
  if (items == null) {
    return;
  }
  const { unitName, id } = keyProps;
  return (
    <div className="container horizontal items">
      {items.map((item, index) => {
        return (
          <div
            className="container vertical item centered"
            key={id + unitName + item.name + index}
          >
            <Hover title={item.name} placement="bottom">
              <img src={mapAssetPath(item.loadoutsIcon)} />
            </Hover>
          </div>
        );
      })}
    </div>
  );
}
