import * as React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import "css/Hover.css";

const BACKGROUND_COLOR = "#1d1d1f";

export const Hover = styled(
  ({ className, title, description = "", ...props }) => (
    <Tooltip
      arrow
      {...props}
      title={
        <div>
          <span className="hoverTitle">{title}</span>
          <br />
          <span className="hoverDescription">{description}</span>
        </div>
      }
      classes={{ popper: className }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: BACKGROUND_COLOR,
    color: "#fff",
    fontSize: "0.8vw",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: BACKGROUND_COLOR,
    },
  },
}));
