// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD2f21NDzwwxv1uUGa6y2e0Ft708kH_C8M",
  authDomain: "tftplacementgame.firebaseapp.com",
  projectId: "tftplacementgame",
  storageBucket: "tftplacementgame.appspot.com",
  messagingSenderId: "373256208476",
  appId: "1:373256208476:web:7cde62826235f4a962a699",
  measurementId: "G-D89DQNBFEE",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
