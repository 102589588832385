import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import uiutils from "utils/uiutils";

import "css/Stars.css";

export default function Stars({ count, rarity, keyProps }) {
  const { unitName, id } = keyProps;
  return (
    <div className="container horizontal stars">
      {[...Array(count)].map((_, index) => (
        <FontAwesomeIcon
          className="star"
          icon={faStar}
          style={{ color: uiutils.getUnitColor(rarity) }}
          key={unitName + id + "stars" + index}
        />
      ))}
    </div>
  );
}
