import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleRight, faCopy } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

import "css/Button.css";

export default function Button({
  disabled,
  text,
  onClick,
  color = "#fff",
  icon,
  size = 4,
}) {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (disabled) {
      setHover(false);
    }
  }, [disabled]);

  const handleMouseEnter = () => {
    if (!disabled) {
      setHover(true);
    }
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  let iconOnLeft = true;
  let width = null;
  let faIcon = null;
  switch (icon) {
    case "play":
      faIcon = faCircleRight;
      iconOnLeft = false;
      break;
    case "copy":
      faIcon = faCopy;
      color = "#a9f1ac";
      break;
    case "twitter":
      faIcon = faTwitter;
      color = "#7ac5f3";
      break;
    default:
      break;
  }

  let baseStyle = {
    cursor: disabled ? null : "pointer",
    fontSize: "calc(" + size + "vw * (var(--main-width) / 80))",
    border: "calc(" + size * 0.08 + "vw * (var(--main-width) / 80)) solid",
    borderRadius: "calc(" + size * 0.2 + "vw * (var(--main-width) / 80))",
    paddingLeft: "calc(" + size * 0.2 + "vw * (var(--main-width) / 80))",
    paddingRight: "calc(" + size * 0.2 + "vw * (var(--main-width) / 80))",
    paddingTop: "calc(" + size * 0.1 + "vw * (var(--main-width) / 80))",
    paddingBottom: "calc(" + size * 0.1 + "vw * (var(--main-width) / 80))",
  };

  const mainColor = disabled ? "rgba(255, 255, 255, 0.5)" : color;
  const baseIconStyle = { fontSize: "100%" };

  const iconComponent = (
    <FontAwesomeIcon
      icon={faIcon}
      style={
        hover
          ? baseIconStyle
          : { ...baseIconStyle, color: mainColor, transition: "all 0.2s" }
      }
    />
  );

  let buttonContent = null;
  if (text != null) {
    if (faIcon == null) {
      buttonContent = text;
    } else {
      let component1 = iconOnLeft ? iconComponent : text;
      let component2 = iconOnLeft ? text : iconComponent;

      buttonContent = (
        <div className="container horizontal centered">
          {component1}
          <div
            style={{
              marginLeft:
                "calc(" + size * 0.2 + "vw * (var(--main-width) / 80))",
            }}
          >
            {component2}
          </div>
        </div>
      );
    }
  } else {
    buttonContent = iconComponent;
  }

  return (
    <div
      className="container centered button"
      onClick={disabled ? null : onClick}
      style={
        hover
          ? baseStyle
          : {
              ...baseStyle,
              borderColor: mainColor,
              color: mainColor,
            }
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {buttonContent}
    </div>
  );
}
