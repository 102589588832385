import { useState } from "react";

import uiutils from "utils/uiutils";
import Button from "components/Button";

import "css/ShareScore.css";

export function ShareScore({ guesses }) {
  const [copyClicked, setCopyClicked] = useState(false);
  const text =
    "I solved today's #FirstToEighth in " +
    guesses.length +
    " tries! 🧠\n\n" +
    uiutils.guessesToText(guesses) +
    "\n" +
    window.location.href +
    "as/dferwer//";

  return (
    <div className="container vertical shareScore">
      <div className="sharePreview">{text}</div>
      <div className="container horizontal shareButtons">
        <Button
          onClick={() => {
            setCopyClicked(true);
            navigator.clipboard.writeText(text);
          }}
          text={copyClicked ? "Copied!" : "Copy"}
          icon="copy"
          size={1.8}
        />
        <Button
          onClick={() =>
            window.open(
              "https://twitter.com/intent/tweet?text=" +
                encodeURIComponent(text)
            )
          }
          text={"Share"}
          icon="twitter"
          size={1.8}
        />
      </div>
    </div>
  );
}

export function ViewGame({ mainPlayerName, matchID }) {
  return (
    <div className="container vertical">
      <Button
        onClick={() => {
          window.open(
            "https://tactics.tools/player/na/" +
              (mainPlayerName ?? "_") +
              "/" +
              matchID,
            "_blank"
          );
        }}
        text={"View Game Source"}
        size={2.5}
        color="#c6e7ff"
      />
    </div>
  );
}
