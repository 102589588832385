import "css/GuessHistory.css";

export default function GuessHistory({ guesses }) {
  return (
    <div className="guessHistory container vertical">
      <h1>Guesses</h1>
      <div className="guesses container horizontal">
        {guesses.map((guess, index) => {
          return (
            <div className="guess container vertical" key={"guess" + index}>
              <span className="container centered">{index + 1}</span>
              {guess.map((decision, index2) => {
                return (
                  <div
                    key={"guessDecision" + index + "," + index2}
                    className={
                      "boardGuess " + (decision ? "correct" : "incorrect")
                    }
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
