import { useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Confetti from "react-confetti";
import { useWindowSize } from "@uidotdev/usehooks";
import RingLoader from "react-spinners/RingLoader";

import { getDaily } from "utils/db";
import uiutils from "utils/uiutils";
import { swap, formatAugmentDescription } from "utils/utils";

import "css/Game.css";

import { ShareScore, ViewGame } from "components/ShareScore";
import GuessHistory from "components/GuessHistory";
import Button from "components/Button";
import Board from "components/Board";
import BoardsContainer from "components/BoardsContainer";

const TFT_MAPS = [
  {
    id: "items",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/tftitems.json",
  },
  {
    id: "traits",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/tfttraits.json",
  },
  {
    id: "units",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/tftchampions.json",
  },
  {
    id: "companions",
    url: "https://raw.communitydragon.org/latest/plugins/rcp-be-lol-game-data/global/default/v1/companions.json",
  },
  {
    id: "descriptions",
    url: "https://raw.communitydragon.org/latest/cdragon/tft/en_us.json",
  },
];

export default function Game() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { width, height } = useWindowSize();

  const [answerDecisions, setAnswerDecisions] = useState(
    new Array(8).fill(null)
  );
  const [isLoading, setIsLoading] = useState(true);

  const [gameData, setGameData] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [hasChangedBoard, setHasChangedBoard] = useState(true);
  const [boardAnimationType, setBoardAnimationType] = useState("fade");
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [boardDatas, setBoardDatas] = useState([]);
  const [boards, setBoards] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(new Array(8).fill(null));
  const [showAnswer, setShowAnswer] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [boardStyles, setBoardStyles] = useState(null);
  const [tftMaps, setTFTMaps] = useState(null);

  const resetGame = async () => {
    setIsLoading(true);
    const response = await getDaily();
    const boardDatas = response?.boards;
    if (boardDatas == null || response == null) {
      return;
    }
    setGameData({
      mainPlayerName: response?.mainPlayerName,
      matchID: response?.matchID,
    });
    setBoardDatas(boardDatas);
    setShowAnswer(false);
    setIsComplete(false);
    const newBoardStyles = {};
    uiutils.getBoardStyles(boardDatas.length).forEach((boardStyle, index) => {
      newBoardStyles[boardDatas[index].puuid] = boardStyle;
    });
    setBoardStyles(newBoardStyles);
  };

  // Load the tft maps and initialize
  useEffect(() => {
    async function func() {
      let maps = {};
      for (let i = 0; i < TFT_MAPS.length; i++) {
        const map = TFT_MAPS[i];
        const response = await axios.get(map.url);
        maps[map.id] = Object.values(response.data);
      }

      setTFTMaps(maps);
      await resetGame();
    }
    func();
  }, []);

  useEffect(() => {
    if (tftMaps == null || boardDatas.length == 0) {
      return;
    }

    const PLACEHOLDER_AUGMENT = tftMaps.items.find(
      (obj) => obj.nameId === "TFT9_Augment_Commander_Experience2Plus"
    );
    PLACEHOLDER_AUGMENT.name = "Tailored Legend Augment";
    PLACEHOLDER_AUGMENT.description =
      "Placeholder for a tailored legend augment.";

    const companions = {};
    const answers = new Array(8);
    const playerBoards = boardDatas
      .map((player) => {
        const augments = player.augments
          .map((nameId) => {
            return tftMaps.items.find((obj) => obj.nameId === nameId);
          })
          .map((augment) => {
            const augmentData = tftMaps.descriptions[0].find(
              (obj) => obj.apiName === augment.nameId
            );
            if (augmentData != null) {
              return {
                ...augment,
                description: formatAugmentDescription(augmentData),
              };
            }
            return augment;
          });

        // Add legend augment placeholder
        if (augments.length < 3) {
          augments.unshift(PLACEHOLDER_AUGMENT);
        }

        // Sort traits by rarity, filter out incomplete traits
        const traits = player.traits
          .filter((trait) => trait.tier_current > 0)
          .map((trait) => ({
            ...trait,
            data: tftMaps.traits.find((obj) => obj.trait_id === trait.name),
          }))
          .sort((a, b) => b.style - a.style);

        // Reverse units so highest cost shows first
        const units = player.units
          .map((unit) => {
            let characterID = unit.character_id;
            return {
              ...unit,
              items: unit.itemNames?.map((itemName) =>
                tftMaps.items.find((obj) => obj.nameId === itemName)
              ),
              data: tftMaps.units.find(
                (obj) =>
                  obj.character_id.toUpperCase() ===
                    characterID.toUpperCase() ||
                  obj.path.toUpperCase().includes(characterID.toUpperCase())
              ),
            };
          })
          .sort((a, b) => b.rarity - a.rarity);

        const companion = tftMaps.companions.find(
          (obj) => obj.contentId === player.companion.content_ID
        );
        const isDuplicate = (companions[companion.speciesName] ?? 0) > 0;
        companions[companion.speciesName] =
          (companions[companion.speciesName] ?? 0) + 1;

        answers[player.placement - 1] = player.puuid;

        return {
          units,
          traits,
          augments,
          companion: {
            ...companion,
            displayName: isDuplicate
              ? companion.speciesName + " " + companions[companion.speciesName]
              : companion.speciesName,
          },
          player: {
            puuid: player.puuid,
            level: player.level,
          },
        };
      })
      .filter((board) => board != null);

    setCorrectAnswers(answers);
    setBoards(playerBoards);
    setTimeout(() => setIsLoading(false), 500);
  }, [boardDatas, tftMaps]);

  const checkAnswer = () => {
    let isCorrect = true;
    const decisions = new Array(correctAnswers.length);
    correctAnswers.forEach((answer, index) => {
      if (boards[index].player.puuid === answer) {
        decisions[index] = true;
      } else {
        isCorrect = false;
        decisions[index] = false;
      }
    });

    setSelectedBoardId(null);
    setGuesses([...guesses, decisions]);
    setAnswerDecisions(decisions);
    setHasChangedBoard(false);
    setIsComplete(isCorrect);
  };

  const onSubmitClick = () => {
    checkAnswer();
  };

  const onBoardClick = (puuid) => {
    if (boardAnimationType !== "slide") {
      setBoardAnimationType("slide");
    }
    if (selectedBoardId === puuid) {
      setSelectedBoardId(null);
    } else if (selectedBoardId == null) {
      setSelectedBoardId(puuid);
    } else {
      const index1 = boards.findIndex(
        (board) => board.player.puuid === selectedBoardId
      );
      const index2 = boards.findIndex((board) => board.player.puuid === puuid);
      const newBoards = swap(boards, index1, index2);
      setBoards(newBoards);
      setSelectedBoardId(null);
      setHasChangedBoard(true);
    }
  };

  const guessHistoryComponent = isTabletOrMobile ? (
    <div
      style={{
        marginTop: "calc(2vw * (var(--main-width) / 80)",
      }}
    >
      <GuessHistory guesses={guesses} />
    </div>
  ) : (
    <div
      className="container centered"
      style={{
        position: "absolute",
        right: "100%",
        top: 0,
        bottom: 0,
        marginRight: "calc(2vw * (var(--main-width) / 80))",
      }}
    >
      <GuessHistory guesses={guesses} />
    </div>
  );

  let shareScoreComponent = null;
  if (true) {
    shareScoreComponent = isTabletOrMobile ? (
      <div
        style={{
          marginTop: "calc(2vw * (var(--main-width) / 80))",
        }}
      >
        <ShareScore guesses={guesses} />
        <ViewGame name={gameData?.mainPlayerName} matchID={gameData?.matchID} />
      </div>
    ) : (
      <div
        className="container centered vertical"
        style={{
          position: "absolute",
          left: "100%",
          top: 0,
          bottom: 0,
          marginLeft: "calc(2vw * (var(--main-width) / 80))",
          gap: "calc(1vw * (var(--main-width) / 80))",
        }}
      >
        <ShareScore guesses={guesses} />
        <ViewGame name={gameData?.mainPlayerName} matchID={gameData?.matchID} />
      </div>
    );
  }

  let confettiComponent = null;
  if (isComplete) {
    confettiComponent = (
      <Confetti
        width={width}
        height={height}
        recycle={false}
        numberOfPieces={200}
      />
    );
  }

  return (
    <div id="gameContainer">
      {confettiComponent}
      {isLoading ? (
        <RingLoader color={"#fff"} />
      ) : (
        <div
          className="container vertical centered"
          style={{ position: "relative" }}
        >
          <p>
            Select two boards to swap them. Hit "Submit" when you're ready to
            guess.
          </p>
          <BoardsContainer boards={boards} decisions={answerDecisions}>
            {boards.map((board, index) => {
              const isLocked = answerDecisions[index] === true;
              return (
                <Board
                  animationType={boardAnimationType}
                  index={index}
                  displayState={
                    selectedBoardId === board.player.puuid ? "selected" : null
                  }
                  onClick={
                    isLocked ? null : () => onBoardClick(board.player.puuid)
                  }
                  id={board.player.puuid}
                  board={board}
                  key={"board" + board.player.puuid}
                  tftMaps={tftMaps}
                  boardStyle={
                    isLocked
                      ? uiutils.getCorrectBoardStyle()
                      : boardStyles[board.player.puuid]
                  }
                />
              );
            })}
          </BoardsContainer>
          <div
            className="container centered"
            style={{
              marginTop: "calc(1vw * (var(--main-width) / 80))",
            }}
          >
            <Button
              disabled={!hasChangedBoard}
              color="#f0d08f"
              text="Submit"
              size={3.5}
              onClick={onSubmitClick}
            />
          </div>
          {guessHistoryComponent}
          {shareScoreComponent}
        </div>
      )}
    </div>
  );
}
